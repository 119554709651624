<template>
  <slot :color-schema="colorSchema" />
</template>

<script setup>
const props = defineProps({
    colorSchema: {
        type: String,
        required: true,
    },
});

const colorSchema = computed(() => props.colorSchema);
provide('colorSchema', colorSchema);
</script>
